export function validSimpleRequiredTextInput(value: string) {
  return value != null && value.trim() !== '';
}

export function validEmailTextInput(email: string) {
  if (validSimpleRequiredTextInput(email)) {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.trim().toLowerCase());
  } else {
    return false;
  }
}